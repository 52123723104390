import axios from 'axios';
import router from '../router';

const state = {
  products: [],
  eurPrice: null,
};

const mutations = {
  SET_PRODUCTS(state, products) {
    state.products = products;
  },
  SET_EURPRICE(state, price) {
    state.eurPrice = price;
  },
};

const actions = {
  async fetchProducts({ commit }, { search }) {
    // console.log('Fetching products with search term:', search);
    const userToken = localStorage.getItem('userToken'); 
    if (!userToken) {
        // Redirect to login page if token is not found
        router.push({ name: 'login' });
        return;
    }

    try {
      const response = await axios.get(`https://api.datafangst.dk/api/rackbeatvarer?search=${search}&limit=200`, { headers: { Authorization: `Bearer ${userToken }` }, } );
      // console.log(response.data.products);
      commit('SET_PRODUCTS', response.data.products);
    } catch (error) {
      console.error('Error fetching products:', error);
      throw error; // Rethrow the error to handle it in the component
    }
  },
  async fetchEuroPrice({commit}, { search }) {
    const userToken = localStorage.getItem('userToken');
    if (!userToken) {
      // Redirect to login page if token is not found
      router.push({ name: 'login' });
      return;
    }
    try {
      const response = await axios.get(`https://api.datafangst.dk/api/currencyprice?search=${search}`, {
        headers: { Authorization: `Bearer ${userToken}` },
      });
      const { eurPrice } = response.data;
      console.log("EUR from store: " + response.data.currency_price);
  
      commit('SET_EURPRICE', response.data.currency_price);
    } catch (error) {
      console.error('Error fetching label EUR price:', error);
    }
  },
};

const getters = {
  getEURPrice: (state) => state.eurPrice, // Getter for EUR price
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};